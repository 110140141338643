import * as React from 'react'

const POI = ({ number, title, children }) => {
    return (
        <div className="poi flex space-x-4 w-full md:w-1/2">
            <span className="bg-gray-100 flex items-center justify-center p-4 rounded-full h-10 w-10 text-persian-green-500">{number}</span>
            <div>
                <h4 className="text-xl">{title}</h4>
                <p className="text-sm">{children}</p>
            </div>
        </div>
    )
}

export default POI