import * as React from 'react'
import POI from './poi'

const HeroPage = ({tag, title, subtitle, children}) => {
    return (
        <section className="px-6 py-24">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">

                    <div className="text-center lg:text-right">
                        <span className="tag">{tag}</span>
                        <h1 className="text-5xl text-gray-800 mb-1">{title}</h1>
                        <h2 className="text-5xl text-persian-green-500 mb-6">{subtitle}</h2>
                        <p>{children}</p>
                    </div>

                    <div className="space-y-6">
                         
                       <h3 className="text-3xl text-persian-green-500 text-center lg:text-left">I vantaggi dell'offerta Cloud</h3>
                        <div className="flex gap-8 flex-wrap md:flex-nowrap">
                            <POI title="Economico" number="1">
                            Nessun investimento iniziale* su hardware e software.
                            </POI>
                            <POI title="Veloce" number="2">
                            Attivazione del servizio in 24 ore in ogni parte d'Italia.
                            </POI>
                        </div>

                        <div className="flex gap-8 flex-wrap md:flex-nowrap">
                            <POI title="Gestito" number="3">
                            Nessun costo di gestione di server e personale tecnico.
                            </POI>
                            <POI title="Scalabile" number="4">
                            Produttività ed efficienza immediata e scalabile con le vostre reali esigenze.
                            </POI>
                        </div>

                        <div className="flex gap-8 flex-wrap md:flex-nowrap">
                            <POI title="Completo" number="5">
                            Soluzione completa con PBX Virtuale e Predictive Dialing.
                            </POI>
                        </div>
                        <p className="text-xs text-center lg:text-left">* Non sono previsti investimenti iniziali tranne le spese di attivazione del servizio (per maggiori informazioni contattaci).</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroPage