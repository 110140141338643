import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'

export default function MyModal() {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      {/* <div className="">
        <button
          type="button"
          onClick={openModal}
          className="rounded-md bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
        >
          Open dialog
        </button>
      </div> */}

    <button className='ml-3 underline' onClick={openModal}>Scopri le Nazioni incluse.</button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Nazioni con traffico telefonico incluso
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className='test-lg font-bold'>Zona 1: </p>
                    <p className="text-sm text-gray-500">
                    Australia (+61); Austria (+43); Belgium (+32); Bulgaria (+359); Canada (+1); Colombia (+57); Croatia (+385); Czechia (+420); Denmark (+45); Estonia (+372); Finland (+358); France (+33); Germany (+49); Greece (+30); Hungary (+36); Ireland (+353); Kosovo (+383); Italy (+39); Latvia (+371); Liechtenstein (+423); Lithuania (+370); Luxembourg (+352); Malta (+356); Mexico (+52); Moldova (+373); Netherlands (+31); Norway (+47); Peru (+51); Poland (+48); Portugal (+351); Romania (+40); San Marino (+378); Slovakia (+421); Slovenia (+386); Spain (+34); Sweden (+46); Switzerland (+41); Ukraine (+380); U.S. Virgin Islands (+1340); United Kingdom (+44); United States (+1)
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Chiudi
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
